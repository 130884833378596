import { ReactNode } from 'react';
import { clsx } from 'clsx'; //shadow-[inset_0px_0px_0px_1px_rgba(0,0,0,1)]

//shadow-[inset_0px_0px_0px_1px_rgba(0,0,0,1)]

interface DraggableProps {
    dragging?: boolean;
    children?: ReactNode;
}

export const Draggable = ({ dragging = false, ...props }: DraggableProps) => {
    return (
        <div
            {...props}
            className={clsx(
                dragging ? 'rotate-2 shadow' : '',
                'flex h-10 w-[200px] items-center justify-center rounded bg-white-off text-blue shadow-[inset_0px_0px_0px_1px] shadow-gray-light transition hover:bg-white',
            )}
        >
            {props.children}
        </div>
    );
};
