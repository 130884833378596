export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const getMasterAPI = () => {
    const hostname = window.location.hostname;
    if (window.location.hostname.endsWith('.nl')) {
        return `https://api.${hostname}`;
    } else {
        return 'http://localhost:3003';
    }
};

export const getMasterAPIWS = () => {
    const hostname = window.location.hostname;
    if (window.location.protocol.startsWith('https')) {
        return `wss://api.${hostname}/trpc`;
    } else {
        return 'ws://localhost:3003/trpc';
    }
};
