import { Button } from '@/components/ui';
import { createColumnHelper } from '@tanstack/react-table';
import { MdMode } from 'react-icons/md';
import { Challenge } from '@teamup/db';
import { trpc } from '@/lib/trpc';
import { Panel } from '@/components/ui/Panel.tsx';
import { Table } from '@/components/ui/elements/Table.tsx';

export const Challenges = () => {
    const { data: challenges, isFetched, refetch } = trpc.challenge.list.useQuery();

    const addChallenge = trpc.challenge.create.useMutation();
    const updateChallenge = trpc.challenge.update.useMutation();

    const challengeHelper = createColumnHelper<Challenge>();
    const challengeColumns = [
        challengeHelper.accessor('name', {
            header: 'Name',
            cell: (info) => info.getValue(),
        }),
        challengeHelper.display({
            id: 'actions',
            header: 'Actions',
            cell: (info) => (
                <Button icon={<MdMode />} size="small" onClick={() => editChallenge(info.row.original)}>
                    Edit
                </Button>
            ),
        }),
    ];

    function newChallenge() {
        addChallenge.mutate({
            data: { name: 'ch5', display: 'ch5', image: 'ch5.png' },
        });
    }

    function editChallenge(updatedChallenge: Challenge) {
        updateChallenge.mutate({
            where: { id: updatedChallenge.id },
            data: { ...updatedChallenge },
        });
        refetch();
    }

    return (
        <>
            {/*<Modal isShowing={isShowing} title={'Create/Edit User'}>*/}
            {/*    <UserForm user={user} onClose={toggle} />*/}
            {/*</Modal>*/}

            <Panel header="Challenges">
                <Button icon={<MdMode />} size="small" onClick={() => newChallenge()}>
                    Create new
                </Button>
                {isFetched && <Table data={challenges} columns={challengeColumns} />}
            </Panel>
        </>
    );
};
