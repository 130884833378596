import { useParams } from 'react-router-dom';
import { Company, SessionWithRelations, UserWithRelations } from '@teamup/db';
import { trpc } from '@/lib/trpc.ts';

interface CompanyDetailProps {
    company?: Company;
    sessions?: SessionWithRelations[] | undefined;
    users?: UserWithRelations[];
}

export const CompanyDetail = ({ company, sessions, users }: CompanyDetailProps) => {
    const licenses = sessions
        ?.flatMap((session) =>
            session.teams.reduce((acc, team) => {
                if (team.licenceUsed === undefined) return acc;
                if (typeof team.licenceUsed === 'number') return acc + team.licenceUsed;
                if (team.licenceUsed) return acc + 1;
                return acc;
            }, 0),
        )
        .reduce((num, acc) => num + acc, 0);

    return (
        <div className="text-black">
            <p className="text-4xl">{company?.name}</p>
            <p className="text-xl">Licenses used</p>
            <p>{JSON.stringify(licenses)}</p>
            <p className="text-xl">Users</p>
            {users?.map((user) => (
                <div key={user.id}>
                    {user.role} - {user.email}
                </div>
            ))}
        </div>
    );
};

export const CompaniesDetail = () => {
    const params = useParams();

    const { data: company } = trpc.company.get.useQuery({ where: { uuid: params.id } });

    const { data: users } = trpc.user.list.useQuery({
        where: {
            company: { uuid: params?.id },
        },
    });

    const { data: sessions }: { data: SessionWithRelations[] | undefined } = trpc.session.list.useQuery({
        where: {
            company: {
                uuid: params.id,
            },
        },
        include: {
            teams: true,
        },
    });

    return <>{company && users && sessions && <CompanyDetail company={company} sessions={sessions} users={users} />}</>;
};
