import { MdArrowForward } from 'react-icons/md';
import { Button } from '@/components/ui';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { SessionWithPartialRelations, SessionWithRelations } from '@teamup/db';

interface SessionPreviewProps {
    session: SessionWithPartialRelations;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const SessionPreview = ({ session }: SessionPreviewProps) => {
    const router = useNavigate();

    function getTiming(session) {
        if (session.state == 'SCHEDULED') {
            return (
                session.start.toLocaleDateString() +
                ' ' +
                session.start.getHours() +
                ':00' +
                ' - ' +
                session.end.getHours() +
                ':00'
            );
        } else if (session.state == 'RUNNING') {
            return session.start.getHours() + ':00' + ' - ' + session.end.getHours() + ':00';
        } else {
            return session.state;
        }
    }

    return (
        <div
            className={clsx(
                session.status === 'SCHEDULED' ? 'bg-blue-lighter text-blue' : 'bg-blue',
                'flex w-[308px] flex-col items-center gap-y-6 p-6 pt-10',
            )}
        >
            <div className="flex flex-col items-center">
                <p className="text-2.5xl">{session.name}</p>
                <p className="truncate px-2 italic">{getTiming(session)}</p>
            </div>
            <div>
                <p>{session.teams?.length} teams</p>
            </div>
            <Button icon={<MdArrowForward />} onClick={() => router(`/dashboard/sessions/${session.uuid}`)}>
                Session details
            </Button>
        </div>
    );
};
