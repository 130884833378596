import { useQuery } from '@tanstack/react-query';
import qs from 'qs';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function HelpLayout() {
    const pathname = useLocation();

    const navigate = useNavigate();

    useEffect(() => {
        if (pathname.pathname === '/dashboard/help') {
            navigate('/dashboard/help/introduction');
        }
    }, [pathname]);

    const { data, isFetched } = useQuery({
        queryKey: ['wiki'],

        queryFn: async () => {
            const strapi = location.hostname.endsWith('.nl')
                ? location.protocol + '//strapi.' + location.hostname
                : import.meta.env.VITE_STRAPI_URL;

            // const strapi = process.env.NEXT_PUBLIC_STRAPI_URL || 'http://localhost:1337';

            const query = {
                fields: ['root'],
                pagination: {
                    pageSize: 10,
                    page: 1,
                },
                // publicationState: 'live',
                locale: ['nl'],
            };

            const q = qs.stringify(query);

            return await fetch(`${strapi}/api/wiki?${q}`, {
                cache: 'no-cache',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => response.json())
                .then((data) => data.data.attributes)
                .catch((error) => {
                    console.error(error);
                    return error.error;
                });
        },
    });

    // Forward to client component
    return (
        <div className="min-w-0 max-w-2xl flex-auto px-4 py-16 lg:max-w-none lg:pl-8 lg:pr-0 xl:px-16">
            {isFetched && <div className="prose" dangerouslySetInnerHTML={{ __html: data.root }} />}
        </div>
    );
}
