import { Button } from '@/components/ui';
import { useEffect, useState } from 'react';
import { MdArrowForward, MdExpandLess, MdExpandMore } from 'react-icons/md';
import { FormProvider, useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import { DatePicker } from '../ui/forms/DatePicker';
import { TimePicker } from '../ui/forms/TimePicker';
import ChallengesPicker from '@/components/ui/forms/ChallengesPicker';
import { InputField } from '@/components/ui';
import { DevTool } from '@hookform/devtools';
import { Panel } from '../ui/Panel';
import { Alert } from '@/components/ui';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { trace } from '@opentelemetry/api';
import { Challenge } from '@teamup/db';
import { trpc } from '@/lib/trpc';
import useAuth from '@/context/useAuth';
import { zodResolver } from '@hookform/resolvers/zod';
import { CreateSessionInput, CreateSessionInputSchema } from '@teamup/db';

interface CreateSessionProps {
    challenges: Challenge[];
}

export const CreateSession = ({ challenges }: CreateSessionProps) => {
    const [advanced, setAdvanced] = useState(false);
    const [isClient, setIsClient] = useState(false);
    const [busy, setBusy] = useState(false);
    const router = useNavigate();
    const { user } = useAuth();
    const [sessionTime, setSessionTime] = useState('');

    const create = trpc.session.create.useMutation();

    useEffect(() => {
        setIsClient(true);
    }, []);

    const formOptions = { resolver: zodResolver(CreateSessionInputSchema) };

    const formMethods = useForm(formOptions);
    const {
        watch,
        register,
        control,
        setError,
        handleSubmit,
        formState: { errors },
    } = formMethods;

    const onSubmit = (data: any) => {
        data = data as CreateSessionInput;
        const start = new Date(data.date).setHours(data.timing.start.getHours(), 0, 0, 0);
        const end = new Date(data.date).setHours(data.timing.end.getHours(), 0, 0, 0);

        data = { ...data, timing: { end: new Date(end), start: new Date(start) } };

        trace
            .getTracer('teamup-frontend')
            .startActiveSpan('create-session', async (span) => {
                await create
                    .mutateAsync(data)
                    .then((res) => {
                        router(`/dashboard/sessions/${res.uuid}`);
                    })
                    .catch(() => setError('root', { message: 'Something went wrong' }))
                    .finally(() => setBusy(false));

                span.end();
            })
            .then();
    };

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === 'timing') {
                const { start, end } = value.timing;

                setSessionTime(
                    `${DateTime.fromJSDate(start).toFormat('HH:mm')} - ${DateTime.fromJSDate(end).toFormat('HH:mm')}`,
                );
            }
        });

        // @ts-ignore
        const timing = watch('timing');
        if (timing) {
            // @ts-ignore
            const { start, end } = timing;

            setSessionTime(
                `${DateTime.fromJSDate(start).toFormat('HH:mm')} - ${DateTime.fromJSDate(end).toFormat('HH:mm')}`,
            );
        }
        return () => subscription.unsubscribe();
    }, [watch]);

    return (
        <div>
            {isClient && <DevTool control={control} placement="bottom-right" />}
            <FormProvider {...formMethods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Panel header="Create session" position="top-left">
                        <div className="flex flex-col gap-y-4">
                            {/*  Input*/}
                            <div className="flex w-full items-end justify-between gap-x-4 ">
                                <label className="grow">
                                    Session name
                                    <InputField
                                        type="text"
                                        placeholder="Session name"
                                        {...register('name')}
                                        id="name"
                                    />
                                </label>
                                <label className="w-[180px]">
                                    Number of teams
                                    <InputField
                                        type="number"
                                        min={1}
                                        placeholder="Number of teams"
                                        {...register('teams')}
                                        id="teams"
                                    />
                                </label>
                                <label hidden>
                                    <InputField
                                        type="number"
                                        value={user?.company.id}
                                        readOnly
                                        {...register('companyId')}
                                        id="companyId"
                                    />
                                </label>

                                <Button type="submit" icon={<MdArrowForward />} busy={busy}>
                                    Create session
                                </Button>
                            </div>
                            {Object.keys(errors).length > 0 && (
                                <motion.div
                                    initial={{ scale: 0.5 }}
                                    animate={{ scale: 1 }}
                                    transition={{
                                        duration: 0.2,
                                    }}
                                >
                                    <Alert type="error">
                                        {Object.entries(errors).map(([key, error]) => (
                                            <p key={key}>{key + ' ' + error?.message?.toString()}</p>
                                        ))}
                                    </Alert>
                                </motion.div>
                            )}
                            <div className="italic text-white-off">
                                {/*//@ts-ignore*/}
                                {`${watch('date')?.toISODate()}, ${sessionTime}`}
                            </div>

                            <Button
                                size="small"
                                icon={advanced ? <MdExpandLess /> : <MdExpandMore />}
                                onClick={() => setAdvanced(!advanced)}
                            >
                                Advanced options
                            </Button>
                            {/*  Advanced view*/}
                        </div>
                    </Panel>
                    <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{
                            height: advanced ? 'auto' : 0,
                            opacity: advanced ? 1 : 0,
                        }}
                        transition={{ duration: 0.3, ease: 'easeInOut' }}
                        style={{ overflow: 'hidden' }}
                    >
                        <div className="flex flex-col gap-y-4 bg-blue-light p-6">
                            {/*Date/Time*/}
                            <div className="flex w-full gap-x-4">
                                <DatePicker />
                                <TimePicker />
                            </div>
                            {/*    Challenge order*/}
                            <div>
                                <ChallengesPicker challenges={challenges} />
                            </div>
                        </div>
                    </motion.div>
                </form>
            </FormProvider>
        </div>
    );
};
