import { CreateSession } from './CreateSession.tsx';
import { SessionPreviewList } from '@/components/dashboard/SessionPreviewList.tsx';
import { Challenge, SessionWithPartialRelations, SessionWithRelations } from '@teamup/db';

interface DashboardProps {
    challenges: Challenge[];
    activeSessions: SessionWithPartialRelations[];
    upcomingSessions: SessionWithPartialRelations[];
}

export const Dashboard = ({ challenges, activeSessions, upcomingSessions }: DashboardProps) => {
    return (
        <div className="flex flex-col gap-y-16">
            <CreateSession challenges={challenges} />
            <SessionPreviewList sessions={activeSessions} header="Active sessions" />
            {upcomingSessions?.length && <SessionPreviewList sessions={upcomingSessions} header="Upcoming sessions" />}
            <div className="mx-auto flex">
                <img src="/images/teamup/Tiny4.png" width={220} height={220} alt="" />
            </div>
        </div>
    );
};
