import { Table } from '@/components/ui/elements/Table.tsx';
import { Button } from '@/components/ui';
import { Panel } from '@/components/ui/Panel';
import { createColumnHelper } from '@tanstack/react-table';
import { MdDelete, MdDownload } from 'react-icons/md';
import { trpc } from '@/lib/trpc';
import { SessionWithRelations, Session } from '@teamup/db';

export const Sessions = () => {
    const { data: sessions, refetch } = trpc.session.list.useQuery();
    const utils = trpc.useUtils();
    const deleteMutation = trpc.session.remove.useMutation();

    const sessionsHelper = createColumnHelper<Session>();
    const sessionsColumns = [
        sessionsHelper.accessor('name', {
            header: 'Name',
            cell: (info) => info.getValue(),
        }),
        sessionsHelper.display({
            id: 'actions',
            header: 'Actions',
            cell: (info) => (
                <>
                    <div className="flex gap-x-2">
                        <Button icon={<MdDelete />} size="small" onClick={() => deleteSession(info.row.original.uuid)}>
                            Delete
                        </Button>
                        <Button
                            icon={<MdDownload />}
                            size="small"
                            onClick={() => downloadSessionData(info.row.original.uuid)}
                        >
                            Download Events
                        </Button>
                    </div>
                </>
            ),
        }),
    ];

    const deleteSession = async (_id: string) => {
        await deleteMutation.mutateAsync({ where: { uuid: _id } });
        await refetch();
    };

    const downloadSessionData = async (_id: string) => {
        const sessionData: SessionWithRelations | null = await utils.session.get.fetch({
            where: { uuid: _id },
            include: { teams: true },
        });

        const teams = sessionData?.teams.map((team) => {
            return { team: team.token, events: team.events };
        });

        const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(teams, null, 4));
        const dlAnchorElem = document.createElement('a');
        dlAnchorElem.setAttribute('href', dataStr);
        dlAnchorElem.setAttribute('download', 'scene.json');
        dlAnchorElem.click();
        dlAnchorElem.remove();
    };

    return (
        <>
            <Panel header="Sessions">{sessions && <Table data={sessions} columns={sessionsColumns} />}</Panel>
        </>
    );
};
