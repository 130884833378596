import { useQuery } from '@tanstack/react-query';
import qs from 'qs';
import clsx from 'clsx';
import {
    ActionImpl,
    KBarAnimator,
    KBarPortal,
    KBarPositioner,
    KBarProvider,
    KBarResults,
    KBarSearch,
    useKBar,
    useMatches,
    VisualState,
} from 'kbar';
import { forwardRef, useEffect, useState } from 'react';
import { InputField } from '@/components/ui';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';

const RenderResults = () => {
    const { results } = useMatches();

    return (
        <div className="text-sm text-gray-800">
            <KBarResults
                items={results}
                onRender={({ item, active }) => {
                    return typeof item === 'string' ? (
                        <div className="py-1 pl-3 text-lg text-blue">
                            <h2 className="text-left"> {item} </h2>
                        </div>
                    ) : (
                        <ResultItem action={item} active={active} />
                    );
                }}
            />
        </div>
    );
};

// Forward Ref
const ResultItem = forwardRef(function ResultItem(
    { action, active }: { action: ActionImpl; active: boolean },
    ref: React.Ref<HTMLDivElement>,
) {
    return (
        <div
            ref={ref}
            className={clsx(
                active ? 'bg-blue-dark text-white-off' : '',
                'flex cursor-default items-center justify-between rounded px-6 py-2 hover:bg-blue-dark',
            )}
        >
            <header className="flex items-center">
                <div className="relative flex select-none flex-col items-start justify-center rounded outline-none">
                    <p className="text-sm"> {action.name} </p>
                </div>
            </header>
        </div>
    );
});

function Kbarro() {
    const { query } = useKBar();

    function onFocus() {
        query.setVisualState(VisualState.animatingIn);
    }

    return (
        <>
            <KBarPortal>
                <KBarPositioner className="fixed inset-0 z-50 overflow-y-auto p-4 backdrop-blur" style={{}}>
                    <KBarAnimator
                        className={
                            //'w-[800px] divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all'
                            'w-[680px] divide-y divide-gray-100 overflow-hidden bg-white ring-transparent'
                        }
                    >
                        <KBarSearch
                            defaultPlaceholder="Search..."
                            className={
                                // 'h-12 w-full border-0 bg-transparent pl-11 pr-4 text-black placeholder:text-black focus:ring-0 sm:text-sm'
                                'h-12 w-full border-0 text-black placeholder:text-gray-light focus:ring-0'
                            }
                        />
                        <RenderResults />
                    </KBarAnimator>
                </KBarPositioner>
            </KBarPortal>
            <div className="mx-auto mb-16 w-full max-w-2xl">
                <div className="relative mt-2 flex w-full items-center">
                    <InputField
                        type="text"
                        name="search"
                        id="search"
                        onFocus={onFocus}
                        placeholder="Search..."
                        className="border-gray-light"
                    />
                    <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                        <kbd className="inline-flex items-center rounded border border-gray-200 px-1 font-sans text-xs text-gray-400">
                            CTRL+K
                        </kbd>
                    </div>
                </div>
            </div>
        </>
    );
}

export default function WikiLayout() {
    const pathname = useLocation();

    const [actions, setActions] = useState();
    const navigate = useNavigate();

    const { data, isFetched } = useQuery({
        queryKey: ['wiki-layout'],

        queryFn: async () => {
            const strapi = location.hostname.endsWith('.nl')
                ? location.protocol + '//strapi.' + location.hostname
                : import.meta.env.VITE_STRAPI_URL;

            const query = {
                populate: {
                    sections: {
                        fields: ['title'],
                        populate: {
                            items: {
                                fields: ['title', 'slug'],
                            },
                        },
                    },
                },
                fields: ['root'],
                pagination: {
                    pageSize: 10,
                    page: 1,
                },
                // publicationState: 'live',
                locale: ['nl'],
            };

            const q = qs.stringify(query);

            return await fetch(`${strapi}/api/wiki?${q}`, {
                cache: 'no-cache',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => response.json())
                .then((data) => data.data.attributes)
                .catch((error) => {
                    return error.error;
                });
        },
    });

    useEffect(() => {
        if (data) {
            const actions = data.sections.data.flatMap((section) =>
                section.attributes.items.data.map((item) => ({
                    id: item.attributes.slug,
                    name: item.attributes.title,
                    section: section.attributes.title,
                    perform: () => navigate(`/dashboard/help/${item.attributes.slug}`),
                })),
            );

            setActions(actions);
        }
    }, [data]);

    // Forward to client component
    return (
        <>
            {actions && (
                <KBarProvider actions={actions}>
                    <div className="flex bg-white">
                        <div className="flex w-full flex-col">
                            <Kbarro />

                            <div className="relative mx-auto flex w-full max-w-7xl flex-auto justify-center sm:px-2 lg:px-8 xl:px-12">
                                <div className="relative block flex-none">
                                    {/*<div className="absolute inset-y-0 right-0 w-[50vw] bg-slate-50 dark:hidden" />*/}
                                    {/*<div className="absolute bottom-0 right-0 top-16 hidden h-12 w-px bg-gradient-to-t from-slate-800 dark:block" />*/}
                                    {/*<div className="absolute bottom-0 right-0 top-28 hidden w-px bg-slate-800 dark:block" />*/}
                                    <div className="sticky -ml-0.5 w-64 overflow-y-auto overflow-x-hidden pl-0.5 pr-4 xl:w-72 xl:pr-16">
                                        {isFetched && (
                                            <div className="text-base">
                                                <ul role="list" className="space-y-9">
                                                    {/*<Link*/}
                                                    {/*    href={'/help'}*/}
                                                    {/*    className={clsx(*/}
                                                    {/*        'block w-full pl-3.5 before:pointer-events-none before:absolute before:-left-1 before:top-1/2 before:h-1.5 before:w-1.5 before:-translate-y-1/2 before:rounded-full',*/}
                                                    {/*        '/help' == pathname*/}
                                                    {/*            ? 'font-semibold text-sky-500 before:bg-sky-500'*/}
                                                    {/*            : 'text-slate-500 before:hidden before:bg-slate-300 hover:text-slate-600 hover:before:block dark:text-slate-400 dark:before:bg-slate-700 dark:hover:text-slate-300',*/}
                                                    {/*    )}*/}
                                                    {/*>*/}
                                                    {/*    Home*/}
                                                    {/*</Link>*/}
                                                    {data.sections.data.map((section) => (
                                                        <li key={section.id}>
                                                            <h2 className="text-2xl font-medium text-blue">
                                                                {section.attributes.title}
                                                            </h2>
                                                            <ul
                                                                role="list"
                                                                className="mt-2 space-y-2 lg:mt-4 lg:space-y-4"
                                                            >
                                                                {section.attributes.items.data.map((item) => (
                                                                    <li key={item.attributes.slug} className="relative">
                                                                        <Link
                                                                            to={
                                                                                '/dashboard/help/' +
                                                                                item.attributes.slug
                                                                            }
                                                                            className={clsx(
                                                                                'block w-full pl-3.5',
                                                                                '/dashboard/help/' +
                                                                                    item.attributes.slug ==
                                                                                    pathname.pathname
                                                                                    ? 'font-semibold text-blue'
                                                                                    : 'text-slate-500',
                                                                            )}
                                                                        >
                                                                            {item.attributes.title}
                                                                        </Link>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <Outlet />
                            </div>
                        </div>
                    </div>
                </KBarProvider>
            )}
        </>
    );
}
