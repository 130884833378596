import { Controller, useForm } from 'react-hook-form';
import { Button } from '@/components/ui';
import { MyListbox } from '@/components/ui/elements/Listbox';
import { Alert, InputField } from '@/components/ui';
import { motion } from 'framer-motion';
import { trpc } from '@/lib/trpc';
import { Company, User, RoleSchema } from '@teamup/db';
import { zodResolver } from '@hookform/resolvers/zod';
import { CreateUserSchema } from '@teamup/db';

export interface UserFormProps {
    user: User | null;
    onClose?: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const UserForm = ({ user, onClose = () => {} }: UserFormProps) => {
    const mode: 'edit' | 'create' = user !== null ? 'edit' : 'create';



    const { data: companies } = trpc.company.list.useQuery();
    const addMutation = trpc.user.create.useMutation();
    const updateMutation = trpc.user.update.useMutation();

    // @ts-ignore
    const formOptions = { resolver: zodResolver(CreateUserSchema) };

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        //@ts-ignore
    } = useForm(formOptions);

    const onSubmit = (data) => {
        data = { ...user, ...data };

        if (mode === 'create') {
            delete data['password2'];
            delete data['mode'];
            console.log('Create this user', data);

            addMutation.mutateAsync(data).then((user) => {
                console.log('Created user', user);
                onClose();
            });

            //post('users', data);
        } else if (mode === 'edit') {
            delete data['mode'];

            updateMutation.mutateAsync({ where: { id: data.id }, data }).then(() => {
                onClose();
            });
            // put(`users/${user?.uuid}`, data).then(() => {
            //     //refetch();
            // });
        }

        // onClose();
    };

    // @ts-ignore
    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex min-w-[400px] flex-col gap-y-2">
            <label htmlFor="mode" hidden={true}>
                Mode
                {/*//@ts-ignore*/}
                <InputField type="text" {...register('mode', { required: true })} value={mode} />
            </label>
            <label>
                Name
                <InputField type="text" {...register('name', { required: true })} defaultValue={user?.name} />
            </label>
            <label htmlFor="email">
                Email
                <InputField type="text" {...register('email', { required: true })} defaultValue={user?.email} />
            </label>
            {mode === 'create' && (
                <>
                    <label htmlFor="password">
                        Password
                        <InputField type="password" {...register('password', { required: true })} />
                    </label>
                    <label htmlFor="password2">
                        Password
                        {/*@ts-ignore*/}
                        <InputField type="password" {...register('password2', { required: true })} />
                    </label>
                </>
            )}

            <label htmlFor="role">
                Role
                <Controller
                    defaultValue={user?.role || 'TRAINER'}
                    render={({ field: { onChange, value } }) => (
                        <MyListbox
                            value={value}
                            onChange={onChange}
                            options={RoleSchema.options}
                            toString={(obj) => obj}
                            // ref={ref}
                            label="label"
                        />
                    )}
                    control={control}
                    name="role"
                />
            </label>
            {companies && (
                <label htmlFor="company">
                    Company
                    <Controller
                        render={({ field: { onChange, value } }) => (
                            <MyListbox
                                label="label"
                                value={value}
                                onChange={onChange}
                                options={companies.map((company: Company) => company.id)}
                                //@ts-ignore
                                toString={(obj: unknown) =>
                                    companies.find((company: Company) => company.id === obj)?.name
                                }
                                // ref={ref}
                            />
                        )}
                        control={control}
                        //@ts-ignore
                        name="companyId"
                        //@ts-ignore
                        defaultValue={user?.companyId}
                    />
                </label>
            )}

            {Object.keys(errors).length > 0 && (
                <motion.div
                    className="rounded bg-red-lighter p-2"
                    initial={{ scale: 0.5 }}
                    animate={{ scale: 1 }}
                    transition={{
                        duration: 0.2,
                    }}
                >
                    {Object.entries(errors).map(([key, error]) => (
                        <Alert type="error" key={key}>
                            <p>{error?.message?.toString()}</p>
                        </Alert>
                    ))}
                </motion.div>
            )}

            <div className="flex justify-end gap-x-2">
                <Button onClick={() => onClose()}>Cancel</Button>
                <Button type="submit">Save</Button>
            </div>
            {/*<DevTool control={control} />*/}
        </form>
    );
};

export default UserForm;
