import { useQuery } from '@tanstack/react-query';
import qs from 'qs';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { clsx } from 'clsx';
import { useParams } from 'react-router-dom';

export default function WikiItemPage() {
    const params = useParams<{ id: string }>();

    const { data, isFetched } = useQuery({
        queryKey: ['wiki-item', params?.id],

        queryFn: async () => {
            const strapi = location.hostname.endsWith('.nl')
                ? location.protocol + '//strapi.' + location.hostname
                : import.meta.env.VITE_STRAPI_URL;

            // const strapi = 'http://localhost:1337';

            const query = {
                fields: ['title', 'content'],
                filters: {
                    slug: {
                        $eq: params?.id,
                    },
                },
                locale: ['nl'],
            };

            const q = qs.stringify(query);

            return await fetch(`${strapi}/api/wiki-items?${q}`, {
                cache: 'no-cache',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => response.json())
                .then((data) => data.data[0].attributes)
                .catch((error) => {
                    return error.error;
                });
        },
    });

    // Forward to client component
    return (
        <div className="min-w-0 max-w-2xl px-4 lg:max-w-none lg:pl-8 lg:pr-0 xl:px-16">
            {isFetched && (
                <article
                    className={clsx(
                        'prose font-noto',
                        'prose-headings:font-normal prose-headings:text-blue',
                        'prose-h1:text-5xl prose-h2:text-3xl prose-h3:text-2xl prose-h4:text-xl',
                        'text-black',
                    )}
                >
                    <ReactMarkdown rehypePlugins={[rehypeRaw]}>{data.content}</ReactMarkdown>
                </article>
            )}
        </div>
    );
}
