import { useForm } from 'react-hook-form';
import { Button, InputField } from '@/components/ui';
import { Company } from '@teamup/db';
import { trpc } from '@/lib/trpc';

export interface CompanyFormProps {
    company: Company;
    onClose?: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const CompanyForm = ({ company, onClose = () => {} }: CompanyFormProps) => {
    const mode: 'edit' | 'create' = company !== null ? 'edit' : 'create';

    const { register, handleSubmit } = useForm<Company>();

    const createMutate = trpc.company.create.useMutation();
    const updateMutate = trpc.company.update.useMutation();

    const onSubmit = (data: Company) => {
        if (mode === 'create') {
            // @ts-ignore
            createMutate.mutateAsync(data).then(() => onClose());
            //post('companies', data);
        } else if (mode === 'edit') {
            updateMutate.mutateAsync({ where: { id: company.id }, data }).then(() => onClose());
            // put(`companies/${company?.id}`, data);
        }
    };

    // @ts-ignore
    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-y-2">
            <label>
                Name
                <InputField type="text" {...register('name', { required: true })} defaultValue={company?.name} />
            </label>
            <div className="flex justify-end">
                <Button onClick={() => onClose()}>Cancel</Button>
                <Button type="submit">Save</Button>
            </div>
            {/*<DevTool control={control} />*/}
        </form>
    );
};
