import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import TraceProvider from '@/lib/tracer';
import { router } from '@/routes';
import setupLocatorUI from '@locator/runtime';

if (process.env.NODE_ENV === 'development') {
    //setupLocatorUI()
}

ReactDOM.createRoot(document.getElementById('root')!).render(
    // <React.StrictMode>
    <HelmetProvider>
        <TraceProvider>
            <RouterProvider router={router} />
        </TraceProvider>
    </HelmetProvider>,
    // </React.StrictMode>,
);
