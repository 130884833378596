import { SessionPreview } from '@/components/dashboard/SessionPreview';
import { PanelHeader } from '../ui/Panel';
import { SessionWithPartialRelations, SessionWithRelations } from '@teamup/db';

interface SessionPreviewListProps {
    header: string;
    sessions: SessionWithPartialRelations[];
}

export const SessionPreviewList = ({ sessions, header }: SessionPreviewListProps) => {
    return (
        <div className="relative">
            <PanelHeader className="absolute -left-8 z-10">{header}</PanelHeader>
            <div className="flex flex-wrap gap-4 pt-12">
                {sessions?.map((session) => <SessionPreview key={session.id} session={session} />)}
            </div>
        </div>
    );
};
