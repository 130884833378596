import React, { createContext, ReactNode, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { trpc } from '@/lib/trpc';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createWSClient, httpLink, splitLink, wsLink } from '@trpc/client';
import { AuthProvider } from '@/context/useAuth';
import superjson from 'superjson';
import { getMasterAPI, getMasterAPIWS } from '@/lib/utils.ts';

const wsClient = createWSClient({
    url: getMasterAPIWS(),
    onOpen: () => {
        console.log('Connected to the websocket connection');
    },
    onClose: console.error,
});

interface TRPCProviderProps {
    children?: ReactNode;
}

interface SessionProps {
    getToken: () => string | undefined;
    setTRPCToken: (token: string) => Promise<void>;
}

export function TRPCProvider({ children }: TRPCProviderProps) {
    const [queryClient] = useState(() => new QueryClient());
    const token = useRef<string>();

    const setTRPCToken = (tokenin: string): Promise<void> => {
        return new Promise((resolve) => {
            token.current = tokenin;
            resolve(undefined);
            setClient(
                trpc.createClient({
                    links: [
                        splitLink({
                            condition: (operation) => operation.type === 'subscription',
                            true: wsLink({
                                client: wsClient,
                                transformer: superjson,
                            }),
                            false: httpLink({
                                transformer: superjson,
                                url: getMasterAPI() + '/trpc',
                                async headers() {
                                    return {
                                        'x-access-token': tokenin,
                                    };
                                },
                            }),
                        }),
                    ],
                }),
            );
        });
    };

    const getToken = useCallback(() => {
        return token.current;
    }, [token]);

    const [client, setClient] = useState(() =>
        trpc.createClient({
            links: [
                httpLink({
                    transformer: superjson,
                    url: getMasterAPI() + '/trpc',
                }),
            ],
        }),
    );

    const memo = useMemo(
        () => ({
            getToken,
            setTRPCToken,
        }),
        [],
    );

    return (
        <TRPCContext.Provider value={memo}>
            <trpc.Provider client={client} queryClient={queryClient}>
                <QueryClientProvider client={queryClient}>
                    <AuthProvider>{children}</AuthProvider>
                </QueryClientProvider>
            </trpc.Provider>
        </TRPCContext.Provider>
    );
}

// @ts-ignore
export const TRPCContext = createContext<SessionProps>();

export default function useTRPC() {
    return useContext(TRPCContext);
}
