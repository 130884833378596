import { clsx } from 'clsx';
import { PropsWithChildren } from 'react';
import { MdDone, MdError, MdInfo, MdWarning } from 'react-icons/md';

interface AlertProps extends PropsWithChildren {
    type: 'success' | 'info' | 'warning' | 'error';
}

export const Alert = ({ children, ...props }: AlertProps) => {
    const style = () => {
        switch (props.type) {
            case 'success':
                return 'bg-green-lighter text-green-dark';
            case 'info':
                return 'bg-amber-lighter text-amber-dark';
            case 'warning':
                return 'bg-orange-lighter text-orange-dark';
            case 'error':
                return 'bg-red-lighter text-red';
            default:
                return 'bg-gray-500';
        }
    };

    const icon = () => {
        switch (props.type) {
            case 'success':
                return <MdDone className="size-6" />;
            case 'info':
                return <MdInfo className="size-6" />;
            case 'warning':
                return <MdWarning className="size-6" />;
            case 'error':
                return <MdError className="size-6" />;
            default:
                return <div className="size-6" />;
        }
    };

    return (
        <div className={clsx(style(), 'flex w-full gap-x-4 rounded p-2')}>
            {icon()}
            <div className="group first-letter:uppercase">{children}</div>
        </div>
    );
};
