import { MdArrowForward } from 'react-icons/md';
import { Table } from '../ui/elements/Table.tsx';
import { Button } from '@/components/ui/elements/Button';
import { createColumnHelper } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { SessionWithRelations } from '@teamup/db';

export const SessionTable = ({ sessions }: { sessions: SessionWithRelations[] }) => {
    // TODO - get isAdmin from session

    const columnHelper = createColumnHelper<SessionWithRelations>();
    const columns = [
        columnHelper.accessor('name', {
            header: 'Name',
            cell: (info) => info.getValue(),
        }),
        columnHelper.accessor('status', {
            header: 'Status',
        }),
        columnHelper.accessor('teams', {
            header: () => '# of teams',
            cell: (info) => info.getValue()?.length,
        }),
        columnHelper.accessor('start', {
            id: 'start',
            header: () => 'Date',
            sortingFn: 'datetime',
            sortDescFirst: true,
            cell: (info) => DateTime.fromJSDate(new Date(info.getValue())).toLocaleString(),
        }),

        columnHelper.display({
            id: 'actions',
            cell: (info) => {
                if (info.row.original.status === 'CLOSED') return null;
                return (
                    <Link to={`/dashboard/sessions/${info.row.original.uuid}`}>
                        <Button icon={<MdArrowForward />} size="small">
                            Details
                        </Button>
                    </Link>
                );
            },
        }),
    ].filter(Boolean);

    const sorting = [
        {
            id: 'start',
            desc: true,
        },
    ];

    return <Table data={sessions} columns={columns} dsorting={sorting} />;
};
