import { clsx } from 'clsx';
import { cloneElement, ComponentProps, ReactNode } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

interface ButtonProps extends ComponentProps<'button'> {
    size?: 'small' | 'default';
    inverted?: boolean;
    rounded?: boolean;
    icon?: ReactNode | undefined;
    busy?: boolean;
}

export const Button = ({
    size,
    type = 'button',
    rounded = false,
    inverted = false,
    busy = false,
    ...props
}: ButtonProps) => {
    const icon = () => {
        if (props.icon) {
            const component = busy ? <AiOutlineLoading3Quarters /> : props.icon;

            // @ts-ignore
            return cloneElement(component, {
                className: clsx(
                    busy ? 'animate-spin' : '',
                    size === 'small' ? 'size-4' : 'size-6 ',
                    'relative',
                    inverted ? 'text-white' : 'text-blue',
                ),
            });
        }
    };

    return (
        <button
            {...props}
            type={type}
            disabled={busy ? true : props.disabled}
            className={clsx(
                'transition-colors',
                size === 'small' ? 'p-1' : 'p-2.5',
                rounded ? 'overflow-hidden rounded-full' : 'max-h-fit max-w-fit',
                rounded ? (size === 'small' ? 'size-4' : 'size-10') : '',
                'flex  max-h-10  items-center justify-center rounded-full',
                inverted
                    ? 'bg-blue hover:bg-blue-light disabled:hover:bg-blue'
                    : 'bg-white-off hover:bg-white disabled:hover:bg-white-off',
                'enabled:hover:shadow',
                busy ? 'cursor-wait opacity-50' : '',
                'disabled:cursor-not-allowed disabled:opacity-50',
                'active:opacity-90',
                props.className,
            )}
        >
            {/*Content*/}
            <div
                className={clsx(
                    size === 'small' ? 'max-h-[16px] min-w-[16px] text-sm' : 'max-h-[20px] min-w-[20px]',
                    'flex items-center justify-center',
                )}
            >
                {/*Text frame*/}
                {props.children && (
                    <div
                        className={clsx(
                            // size === 'small' ? 'min-h-[16px] min-w-[16px] text-sm' : 'h-[20px] min-w-[20px]',
                            'flex',
                            rounded ? '' : size === 'small' ? 'px-1' : 'px-2',
                            'text-center',
                            inverted ? 'text-white' : 'text-blue',
                            'truncate',
                        )}
                    >
                        {props.children}
                    </div>
                )}

                {/*Icon*/}
                {icon && <>{icon()}</>}
            </div>
        </button>
    );
};
