import { Modal, useModal } from '@/components/ui/elements/Modal.tsx';
import { Table } from '@/components/ui/elements/Table.tsx';
import { Button } from '@/components/ui';
import { CompanyForm } from '@/components/ui/forms/models/CompanyForm';
import { Panel } from '@/components/ui/Panel';
import { createColumnHelper } from '@tanstack/react-table';
import { useState } from 'react';
import { MdDelete, MdMode } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { Company } from '@teamup/db';
import { trpc } from '@/lib/trpc';
export const Companies = () => {
    const { data: companies, refetch } = trpc.company.list.useQuery();
    const deleteMutation = trpc.company.delete.useMutation();
    const router = useNavigate();
    const [company, setCompany] = useState<Company | null>(null);
    const { isShowing, toggle } = useModal();

    const companyHelper = createColumnHelper<Company>();
    const companyColumns = [
        companyHelper.accessor('name', {
            header: 'Name',
            cell: (info) => info.getValue(),
        }),
        companyHelper.display({
            id: 'actions',
            header: 'Actions',
            cell: (info) => (
                <div className="flex gap-x-2">
                    <Button
                        icon={<MdMode />}
                        size="small"
                        onClick={() => router(`/dashboard/admin/companies/${info.row.original.uuid}`)}
                    >
                        Details
                    </Button>
                    <Button icon={<MdMode />} size="small" onClick={() => editCompany(info.row.original)}>
                        Edit
                    </Button>
                    <Button icon={<MdDelete />} size="small" onClick={() => deleteCompany(info.row.original.id)} />
                </div>
            ),
        }),
    ];

    const deleteCompany = (id) => {
        deleteMutation.mutate({ where: { id } });
        void refetch();
    };

    const editCompany = (company: Company): void => {
        setCompany(company);
        toggle();
    };

    function newCompany(): void {
        setCompany(null);
        toggle();
    }

    function onClose() {
        refetch().then();
        toggle();
    }

    return (
        <>
            {company && (
                <Modal isShowing={isShowing} title="Create/Edit company">
                    <CompanyForm company={company} onClose={onClose} />
                </Modal>
            )}
            <Panel header="companies">
                <Button icon={<MdMode />} size="small" onClick={() => newCompany()}>
                    Create new
                </Button>

                {companies && <Table data={companies} columns={companyColumns} />}
            </Panel>
        </>
    );
};
