import { Controller, useFormContext } from 'react-hook-form';
import ReactSlider from 'react-slider';
import { clsx } from 'clsx';
import { DateTime } from 'luxon';
import { Button } from '@/components/ui';

const originalError = console.error.bind(console.error);
//@ts-ignore
console.error = (...args: unknown[]) =>
    !args.toString().includes('Warning: A props object containing a "key" prop is being spread into JSX:') &&
    !args.toString().includes('ReactSlider') &&
    originalError(...args);

export const TimePicker = () => {
    const { control } = useFormContext(); // retrieve all hook methods

    const getDefault = () => {
        const start = new Date();
        start.setMinutes(0, 0, 0);
        const end = new Date();
        if (start.getHours() > 14) {
            end.setHours(23, 0, 0, 0);
        } else {
            end.setHours(18, 0, 0, 0);
        }

        return { start, end };
    };

    return (
        <div className="w-full">
            <label htmlFor="timing" className="block text-white-off">
                Time
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
                <Controller
                    control={control}
                    name="timing"
                    defaultValue={getDefault()}
                    render={({ field: { onChange, value } }) => (
                        <div className="flex h-9 w-full items-center">
                            <ReactSlider
                                min={1}
                                max={23}
                                className="flex w-full items-center"
                                value={[value.start.getHours(), value.end.getHours()]}
                                onChange={(sliderValue, index) => {
                                    const newVal = value;
                                    if (index == 0) {
                                        newVal['start'].setHours(sliderValue[index]);
                                        newVal['end'].setHours(Math.max(sliderValue[1], sliderValue[0]));
                                    } else if (index == 1) {
                                        newVal['start'].setHours(Math.min(sliderValue[1], sliderValue[0]));
                                        newVal['end'].setHours(sliderValue[index]);
                                    } else {
                                        newVal['start'].setHours(sliderValue[0]);
                                        newVal['end'].setHours(sliderValue[1]);
                                    }
                                    onChange(newVal);
                                }}
                                renderThumb={(props, state) => (
                                    <div key={state.index} {...props}>
                                        <Button size="small" className="size-6 cursor-grab"></Button>

                                        {/*<div className={'h-6 w-6 cursor-grab rounded-full bg-white-off'} />*/}

                                        <div className="absolute -left-2">
                                            <p>
                                                {DateTime.fromFormat(state.valueNow.toString(), 'H').toFormat('HH:mm')}
                                            </p>
                                        </div>
                                    </div>
                                )}
                                renderTrack={(props, state) => (
                                    <div
                                        key={state.index}
                                        {...props}
                                        className={clsx(
                                            //use 1/4 height or width depending on the orientation and make sure to center it.
                                            'flex rounded-full',
                                            state.index === 1 ? 'h-2 bg-white-off' : 'h-1 bg-blue-lighter',
                                        )}
                                    ></div>
                                )}
                                pearling
                                minDistance={1}
                            />
                        </div>
                    )}
                />
            </div>
        </div>
    );
};
