import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { ComponentProps, useEffect, useState } from 'react';
import { clsx } from 'clsx';
import { MdReplay, MdSwapHoriz } from 'react-icons/md';
import { Draggable as TUDraggable } from './Draggable';
import { Controller, useFormContext } from 'react-hook-form';
import { Challenge } from '@teamup/db';
import { Button } from '@/components/ui';

// a little function to help us with reordering the result
const reorder = (list: Challenge[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

//eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO Find types of droppableObjects
const move = (source: Challenge[], destination: Challenge[], droppableSource: any, droppableDestination: any) => {
    const sourceClone = Array.from(source) as Challenge[];
    const destClone = Array.from(destination) as Challenge[];
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    return {
        sourceClone,
        destClone,
    };
};

interface ChallengesPickerProps extends ComponentProps<'input'> {
    challenges: Challenge[];
}

const ChallengesPicker = ({ challenges }: ChallengesPickerProps) => {
    const [items, setItems] = useState<Challenge[]>([]);
    const [dismissed, setDismissed] = useState<Challenge[]>([]);

    useEffect(() => {
           reset();
    }, []);

    const { control } = useFormContext();

    //eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO Set to correct type
    const onDragEnd = (result: any) => {
        const { source, destination } = result;

        let itemsNew = [...items];

        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            if (source.droppableId === 'droppable') {
                itemsNew = reorder(items, result.source.index, result.destination.index);
                // setItems(reorder(items, result.source.index, result.destination.index));
            }
        } else {
            if (source.droppableId === 'droppable') {
                const { sourceClone, destClone } = move(items, dismissed, source, destination);

                // setItems(sourceClone);
                itemsNew = sourceClone;
                setDismissed(destClone);
            } else {
                const { sourceClone, destClone } = move(dismissed, items, source, destination);
                itemsNew = destClone;
                // setItems(destClone);
                setDismissed(sourceClone);
            }
        }
        setItems(itemsNew);
        return itemsNew;
    };

    function reset() {
        const defaultItems : Challenge[] = [];
        const defaultDismissed : Challenge[] = [];

        challenges.forEach(challenge => {
            if(challenge.display.endsWith('(hard)')){
                defaultDismissed.push(challenge);
            }else{
                defaultItems.push(challenge);
            }
        })

        setItems(defaultItems);
        setDismissed(defaultDismissed);

        return challenges;
    }

    return (
        <>
            {items.length > 0 && (
        <Controller
            control={control}
            name="challenges"
            defaultValue={items}
            render={({ field: { onChange } }) => (
                <DragDropContext
                    onDragEnd={(e) => {
                        const items = onDragEnd(e);
                        if (items) {
                            onChange(items);
                        }
                    }}
                >
                    <div className="flex gap-x-2">
                        <div className="w-[200px]">
                            <>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <>
                                            <h3>Challenge order</h3>
                                            <div
                                                className={clsx(
                                                    'mb-4 min-h-[40px] rounded bg-blue shadow-[0px_0px_0px_1px] shadow-blue-dark',
                                                    snapshot.isDraggingOver ? '' : '',
                                                )}
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {items.map((item: Challenge, index: number) => (
                                                    <Draggable
                                                        key={item.id.toString()}
                                                        draggableId={item.id.toString()}
                                                        index={index}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <TUDraggable dragging={snapshot.isDragging}>
                                                                    {item.display}
                                                                </TUDraggable>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        </>
                                    )}
                                </Droppable>
                            </>
                            <Button
                                className="mx-auto"
                                size="small"
                                icon={<MdReplay />}
                                onClick={() => onChange(reset())}
                            >
                                Reset to default
                            </Button>
                        </div>
                        <div>
                            <MdSwapHoriz className="mt-7 size-8" />
                        </div>
                        <div className="w-[200px]">
                            <Droppable droppableId="droppable-dismissed">
                                {(provided) => (
                                    <>
                                        <h3>Not selected</h3>
                                        <div
                                            className="mb-4 min-h-[40px] rounded bg-blue shadow-[0px_0px_0px_1px] shadow-blue-dark"
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {dismissed.map((item, index) => (
                                                <Draggable
                                                    key={item.id.toString()}
                                                    draggableId={item.id.toString()}
                                                    index={index}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <TUDraggable dragging={snapshot.isDragging}>
                                                                {item.display}
                                                            </TUDraggable>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    </>
                                )}
                            </Droppable>
                        </div>
                    </div>
                </DragDropContext>
            )}
        />)}</>

    );
};

export default ChallengesPicker;
