import { Popover } from '@headlessui/react';
import { clsx } from 'clsx';
import { motion, useAnimate } from 'framer-motion';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { MdChevronLeft, MdChevronRight, MdEvent } from 'react-icons/md';
import { Button, InputField } from '@/components/ui';

export const DatePicker = () => {
    const { control } = useFormContext();

    const [viewingDates, setViewingDates] = useState<DateTime>(DateTime.now());
    const [days, setDays] = useState<DateTime[]>([]);

    const [scope] = useAnimate();

    useEffect(() => {
        if (viewingDates) {
            const day = viewingDates.startOf('month');
            const dayZ: DateTime[] = [];
            for (let i = 1; i < day.weekday; i++) {
                dayZ.unshift(day.minus({ days: i }));
            }
            for (let i = 0; i <= 35 - day.weekday; i++) {
                dayZ.push(day.plus({ days: i }));
            }
            setDays(dayZ);
        }
    }, [viewingDates]);

    function nextMonth() {
        setViewingDates(viewingDates.plus({ months: 1 }));
    }

    function prevMonth() {
        setViewingDates(viewingDates.minus({ months: 1 }));
    }

    return (
        <div className="w-[200px] text-black">
            <label htmlFor="date" className="text-white-off">
                Select a date
            </label>
            <Controller
                control={control}
                name="date"
                defaultValue={DateTime.now()}
                render={({ field: { onChange, value, ref } }) => (
                    <Popover className="relative">
                        {({ open }) => (
                            /* Use the `open` state to conditionally change the direction of the chevron icon. */
                            <>
                                <Popover.Button>
                                    <div className="w-[200px]">
                                        <InputField hidden type="text" defaultValue={value} ref={ref} />
                                        <InputField
                                            type="text"
                                            value={value.toISODate()}
                                            readOnly
                                            icon={<MdEvent />}
                                            ref={ref}
                                        />
                                    </div>
                                </Popover.Button>
                                <Popover.Panel className="absolute z-40 w-[200px]">
                                    {({ close }) => (
                                        <motion.div
                                            initial={{ height: 0, opacity: 0 }}
                                            animate={{
                                                height: open ? 'auto' : 0,
                                                opacity: open ? 1 : 0,
                                            }}
                                            transition={{
                                                duration: 0.3,
                                                ease: 'easeInOut',
                                            }}
                                            style={{ overflow: 'hidden' }}
                                            ref={scope}
                                            className="absolute w-[273px] rounded border border-gray-light bg-white-off p-4"
                                        >
                                            <div className=" flex w-full flex-col items-center justify-items-center text-center">
                                                <div className="flex w-full items-center text-black">
                                                    <Button
                                                        rounded={true}
                                                        onClick={prevMonth}
                                                        icon={<MdChevronLeft />}
                                                    />
                                                    <div className="flex-auto capitalize text-blue">
                                                        {viewingDates.monthLong} {viewingDates.year}
                                                    </div>
                                                    <Button
                                                        rounded={true}
                                                        onClick={nextMonth}
                                                        icon={<MdChevronRight />}
                                                    />
                                                </div>
                                                <div
                                                    className={clsx('mt-2 grid w-full grid-cols-7 leading-6 text-blue')}
                                                >
                                                    <div>M</div>
                                                    <div>T</div>
                                                    <div>W</div>
                                                    <div>T</div>
                                                    <div>F</div>
                                                    <div>S</div>
                                                    <div>S</div>
                                                </div>
                                                <div className="isolate mt-2 grid w-full grid-cols-7 gap-px">
                                                    {days.map((day: DateTime, dayIdx) => (
                                                        <>
                                                            {day.month === viewingDates.month ? (
                                                                <button
                                                                    key={dayIdx}
                                                                    type="button"
                                                                    className={clsx('group relative rounded-lg p-1')}
                                                                    onClick={() => {
                                                                        setViewingDates(day);
                                                                        onChange(day);
                                                                        close();
                                                                    }}
                                                                >
                                                                    <time
                                                                        // dateTime={day.toISODate()}
                                                                        className={clsx(
                                                                            'mx-auto flex size-7 items-center justify-center rounded-full',
                                                                            day.toISODate() === value.toISODate()
                                                                                ? 'bg-blue text-white'
                                                                                : '',
                                                                            'active:bg-blue-dark',
                                                                            'hover:bg-blue-lighter',
                                                                        )}
                                                                    >
                                                                        {day.day}
                                                                    </time>
                                                                </button>
                                                            ) : (
                                                                <div></div>
                                                            )}
                                                        </>
                                                    ))}
                                                </div>
                                            </div>
                                        </motion.div>
                                    )}
                                </Popover.Panel>
                            </>
                        )}
                    </Popover>
                )}
            />
        </div>
    );
};
