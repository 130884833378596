import { clsx } from 'clsx';
import { cloneElement, ComponentPropsWithoutRef, forwardRef, ReactNode } from 'react';
import { FieldError } from 'react-hook-form';

interface InputFieldProps extends ComponentPropsWithoutRef<'input'> {
    label?: string;
    icon?: ReactNode;
    invalid?: FieldError | undefined | unknown;
}

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(({ label, ...props }: InputFieldProps, ref) => {
    //console.log(props.icon);

    const icon = () => {
        if (props.icon) {
            // @ts-ignore
            return cloneElement(props.icon, {
                className: clsx('size-6 rounded-full text-black hover:bg-gray-light/50'),
            });
        }
    };

    return (
        <label className="w-full">
            {label && <p>{label}</p>}
            <div className="relative">
                <input
                    {...props}
                    id={props.id}
                    ref={ref}
                    className={clsx(
                        'form-input h-10 w-full rounded border-gray-light text-black placeholder:text-gray-light focus:border-blue-dark',
                        props.invalid ? 'border-red bg-red-lighter' : '',
                    )}
                />
                {props.icon && (
                    <div className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3">{icon()}</div>
                )}
            </div>
        </label>
    );
});

InputField.displayName = 'InputField';
