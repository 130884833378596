import {
    BatchSpanProcessor,
    ConsoleSpanExporter,
    SimpleSpanProcessor,
    WebTracerProvider
} from '@opentelemetry/sdk-trace-web';
import { Resource } from '@opentelemetry/resources';
import {
    SEMRESATTRS_DEPLOYMENT_ENVIRONMENT,
    SEMRESATTRS_SERVICE_NAME,
    SEMRESATTRS_SERVICE_VERSION
} from '@opentelemetry/semantic-conventions';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { B3Propagator } from '@opentelemetry/propagator-b3';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';
import React, { ReactNode } from 'react';
import { parseURL } from 'whatwg-url';
import opentelemetry from '@opentelemetry/api';

const provider = new WebTracerProvider({
    resource: new Resource({
        [SEMRESATTRS_SERVICE_NAME]: 'teamup-frontend',
        [SEMRESATTRS_DEPLOYMENT_ENVIRONMENT]: import.meta.env.VITE_ENVIRONMENT,
        [SEMRESATTRS_SERVICE_VERSION]: import.meta.env.VITE_VERSION
    })
});

// const traceUrl =
//     import.meta.env.VITE_ENVIRONMENT === 'editor'
//         ? 'http://localhost:4318/v1/traces'
//         : 'https://otel.schuur.thebarngames.nl/v1/traces';
const traceUrl = 'https://otel.schuur.thebarngames.nl/v1/traces';

provider.addSpanProcessor(
    new BatchSpanProcessor(
        new OTLPTraceExporter({
            url: traceUrl,
            headers: {
                'connect-src': traceUrl
            }
        })
    )
);
// provider.addSpanProcessor(new SimpleSpanProcessor(new ConsoleSpanExporter()));
provider.register({
    propagator: new B3Propagator()
});

const trpcRegex = new RegExp('(\/trpc\/)');

// Registering instrumentations
registerInstrumentations({
    instrumentations: [
        getWebAutoInstrumentations({
            // '@opentelemetry/instrumentation-xml-http-request': {
            //     ignoreUrls: [new RegExp('(socket.io)+')],
            //     propagateTraceHeaderCorsUrls: [new RegExp(getMasterAPI())],
            //     clearTimingResources: true,
            // },
            '@opentelemetry/instrumentation-fetch': {
                ignoreNetworkEvents: true,
                ignoreUrls: [new RegExp('(socket.io)+')],
                propagateTraceHeaderCorsUrls: [
                    new RegExp('localhost:3003'),
                    new RegExp('tstest.thebarngames.nl'),
                    new RegExp('teamup.thebarngames.nl')
                ],
                clearTimingResources: true,
                //@ts-ignore
                applyCustomAttributesOnSpan(span, req, res: Response) {

                    //if (res.url.startsWith('http://localhost:3003/trpc')) {
                    if (trpcRegex.test(res.url)) {
                        const hostinfo = parseURL(res.url);
                        // @ts-ignore
                        span.updateName(hostinfo?.path.join('/'));
                    }
                }
            },
            '@opentelemetry/instrumentation-user-interaction': {
                enabled: false
            },
            '@opentelemetry/instrumentation-document-load': {
                enabled: false
            }
        })
    ],
    tracerProvider: provider
});

export const tracer = opentelemetry.trace.getTracer('teamup-frontend');

export default function TraceProvider({ children }: { children: ReactNode }) {
    return <>{children}</>;
}
