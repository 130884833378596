import { motion } from 'framer-motion';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { MdArrowForward, MdClose } from 'react-icons/md';
import { Alert, Button, InputField } from '@/components/ui';
import { trpc } from '@/lib/trpc';
import { forgotPWSchema } from '@teamup/db';
import { zodResolver } from '@hookform/resolvers/zod';

interface ForgotPasswordFormProps {
    onClose: () => void;
}

export function ForgotPasswordForm({ ...props }: ForgotPasswordFormProps) {
    const formOptions = { resolver: zodResolver(forgotPWSchema) };

    const resetPass = trpc.auth.reset_password.useMutation();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm(formOptions);

    //eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO Set to ForgotPassWDType
    const onSubmit = async (data: any) => {
        resetPass.mutateAsync(data.email).finally(() => {
            setInfo("We've sent recovery instructions to this email address");
        });
    };

    const [info, setInfo] = useState<string | undefined>();

    return (
        <div className="w-[468px] text-left">
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-y-4">
                <p>Enter your email address below to receive instructions on how to reset your password.</p>
                <label htmlFor="email">
                    Email address
                    <InputField
                        id="email"
                        type="text"
                        autoComplete="email"
                        placeholder="Email"
                        {...register('email')}
                    />
                </label>

                {Object.keys(errors).length > 0 && (
                    <motion.div
                        initial={{ scale: 0.5 }}
                        animate={{ scale: 1 }}
                        transition={{
                            duration: 0.2,
                        }}
                    >
                        <Alert type="error">
                            {Object.entries(errors).map(([key, error]) => (
                                <div key={key}>{error?.message?.toString()}</div>
                            ))}
                        </Alert>
                    </motion.div>
                )}

                {info && (
                    <motion.div
                        initial={{ scale: 0.5 }}
                        animate={{ scale: 1 }}
                        transition={{
                            duration: 0.2,
                        }}
                    >
                        <Alert type="info">
                            <div>{info}</div>
                        </Alert>
                    </motion.div>
                )}

                <div className="flex justify-end gap-x-4">
                    <Button icon={<MdClose />} onClick={() => props.onClose()}>
                        Close
                    </Button>
                    {!info && (
                        <Button type="submit" icon={<MdArrowForward />}>
                            Submit
                        </Button>
                    )}
                </div>
            </form>
        </div>
    );
}
